(function(w, d) {
    w.auto_update_config.forEach(function(o) {
        let cards = [],
            searchJSON = o.search_url,
            block_assets = [],
            new_assets = [],
            current_time = getCurrentTime();

        function checkForUpdates(){
            if ('requestIdleCallback' in window) {
                requestIdleCallback(function(){
                    current_time = getCurrentTime();
                    fetchAssets();
                    setTimeout(checkForUpdates, 900000);
                }, {timeout:2000});
            } else {
                current_time = getCurrentTime();
                fetchAssets();
                setTimeout(checkForUpdates, 900000);
            }
        }

        // Set a timer for 15 mins
        setTimeout(checkForUpdates, 900000);

        // Fetch assets
        function fetchAssets() {
            block_assets = [];
            new_assets = [];

            var update_items = d.getElementById('block-' + o.block_id).querySelectorAll('.updates-item');
            for (i = 0; i < update_items.length; i++) {
                block_assets.push(update_items[i].dataset.uuid);
            }

            fetch(searchJSON, {
                credentials:'same-origin' // Essential for making this work in preview
            }).then(function(response) {
                if (response.status !== 200) {
                    __tnt.log('Error. Status Code: ' + response.status);
                    return;
                }

                // Examine response
                response.json().then(function(data) {

                    // Compare response assets with the block assets and if we
                    // don't find the response asset in the block, it's new
                    for (let i = 0; i < data.count; i++) {
                        let match = false;
                        for (let j = 0; j < block_assets.length; j++) {
                            if (data.rows[i].uuid == block_assets[j]) {
                                match = true;
                            }
                        }
                        if (match === false) {
                            new_assets.push(data.rows[i]);
                        }
                    }

                    if (new_assets.length > 0) {
                        showUpdatesButton();
                    } else {
                        hideUpdatesButton();
                    }
                });
            }).catch(function(err) {
                __tnt.log('Fetch Error: ', err);
            });
        }

        let btn = d.getElementById('block-' + o.block_id).querySelector(o.updates_btn_class);
        btn.addEventListener('click', function(){
            populateCards(new_assets);
            hideUpdatesButton();
            cards = [];
        });

        function hideUpdatesButton() {
            btn.parentNode.classList.remove('shown');
        }

        function showUpdatesButton() {
            let amount = new_assets.length;
            btn.parentNode.classList.add('shown');
            btn.textContent = (amount == 1) ? amount + ' New Update' : amount + ' New Updates';
        }

        // Populate cards with fetched assets
        function populateCards(assets) {
            fillTemplate(assets);
            renderCards();
        }

        // Insert cards
        function renderCards() {
            let dz = d.getElementById('block-' + o.block_id).querySelector(o.drop_zone_class);
            let df = d.createDocumentFragment();
            cards.forEach(function(card) {
                df.insertBefore(card, df.childNodes[0]);
                df.querySelector('div').style.display = 'block';
            });
            dz.insertBefore(df, dz.childNodes[0]);
        }

        // Fill template
        function fillTemplate(assets) {
            assets.forEach(function(asset) {
                let clone = d.getElementById(o.template_id).content.cloneNode(true);
                let card = clone.querySelector('.updates-item');

                // Card ID
                card.id = card.id.replace(/{{uuid}}/g, asset.uuid);
                card.setAttribute('data-uuid', card.getAttribute('data-uuid').replace(/{{uuid}}/g, asset.uuid) );

                // Title
                clone.querySelector('.tnt-headline a').textContent = asset.title;

                // Byline
                if (o.show_byline) {
                    if (asset.byline) {
                        clone.querySelector('.tnt-byline').innerHTML = asset.byline;
                    } else {
                        var emptyByline = clone.querySelector('.card-byline');
                        emptyByline.parentNode.removeChild(emptyByline);
                    }
                }

                // Author
                if (o.show_author){
                    var authorCard = clone.querySelector('.card.author');
                    if (asset.authors.length > 0) {
                        var author = asset.authors[0];
                        authorCard.classList.remove('card-author-{{uuid}}');
                        authorCard.classList.add('card-author-' + author.uuid);
                        // Manage author name
                        clone.querySelector('.tnt-user-name').innerHTML = getAuthorName(author);
                        // Profile link
                        var profileLinks = clone.querySelectorAll('.tnt-user-profile-link');
                        for (i = 0; i < profileLinks.length; i++) {
                            profileLinks[i].href = profileLinks[i].href.replace(/%7B%7Bscreen_name%7D%7D/g, author.screen_name);
                        }
                        // Avatar 
                        // check for domain.com/avatar/.../ because software doesn't handle gravatars properly
                        if (author.avatar != null && author.avatar.indexOf('/avatar/') == -1) {
                            var avatar = clone.querySelector('.tnt-user-avatar');
                            avatar.removeAttribute('data-src');
                            avatar.src = author.avatar;
                            avatar.alt = author.screen_name;
                        } else {
                            // Remove author avatar 
                            var avatarBox = clone.querySelector('.avatar-box');
                            avatarBox.parentNode.removeChild(avatarBox);
                        }
                    } else {
                        // Remove author card
                        authorCard.parentNode.removeChild(authorCard);
                    }
                }

                // Date
                var date = clone.querySelector('.tnt-date');
                var asset_starttime = parseInt(asset.starttime.utc, 10) / 1000;
                var asset_lastupdated = parseInt(asset.lastupdated.utc, 10) / 1000;

                if (
                    asset_lastupdated
                    && current_time - (30*86400) < asset_starttime  // asset has a start time no less than 30 days ago
                    && current_time - (7*86400) < asset_lastupdated // asset was updated in the last 7 days
                    && current_time > asset_lastupdated             // asset was updated in the past
                    && 3600 > current_time - asset_lastupdated      // the difference between the current time and the asset last updated has to be less than 1 hour
                ) {
                    var assetDate = prettyDate(asset_lastupdated, current_time);
                    date.setAttribute('datetime', asset.lastupdated.iso8601);
                    date.innerHTML = 'Updated ' + assetDate;
                    clone.querySelector('.updates-item').classList.add('updates-recent');

                // asset was created less than 1 hour ago
                } else if (3600 > current_time - asset_starttime) {
                    var assetDate = prettyDate(asset_starttime, current_time);
                    date.setAttribute('datetime', asset.starttime.iso8601);
                    date.innerHTML = assetDate;
                    clone.querySelector('.updates-item').classList.add('updates-recent');

                } else {
                    var assetDate = new Date( asset.starttime.iso8601 ).toLocaleDateString('en-us', {year:'numeric', month:'short', day:'numeric'});
                    date.setAttribute('datetime', asset.starttime.iso8601);
                    date.innerHTML = assetDate;
                }

                // Summary/lead
                if (o.text_truncate > 0) {
                    var lead = clone.querySelector('.tnt-summary'),
                        assetSummary = getSummary(asset);
                    if (assetSummary) {
                        lead.innerHTML = assetSummary;
                    } else {
                        var emptyLead = clone.querySelector('.card-lead');
                        emptyLead.parentNode.removeChild(emptyLead);
                    }
                }

                // Section
                var noSection = true;
                if (o.show_section) {
                    var section = clone.querySelector('.tnt-section-tag');
                    if (asset.sections[0]) {
                        var sTag = asset.sections[0].replace(/_/g, ' ');
                        section.innerHTML = sTag;
                        noSection = false;
                    } else {
                        var emptySection = clone.querySelector('.card-label-section');
                        emptySection.parentNode.removeChild(emptySection);
                    }
                }

                // Flags
                var noFlags = true;
                if (o.show_flags) {
                    var flag_set = clone.querySelector('.card-label-flags'),
                        flag = clone.querySelector('.tnt-flag');
                    if (asset.flags.length > 0){
                        for (var i = 0; i < asset.flags.length; i++) {
                            var outFlag = flag.cloneNode();
                            outFlag.innerHTML = asset.flags[i].replace(/_/g, ' ');
                            outFlag.classList.remove('label-flag-flag');
                            outFlag.classList.add('label-flag-' + asset.flags[i].replace(/_/g, '-'));
                            flag_set.appendChild(outFlag);
                            flag_set.appendChild(document.createTextNode(' '));
                        }
                        flag.parentNode.removeChild(flag);
                        noFlags = false;
                    } else {
                        flag_set.parentNode.removeChild(flag_set);
                    }
                }

                // If no section or flags then remove area
                if ((o.show_section || o.show_flags) && noSection && noFlags) {
                    var emptyLabels = clone.querySelector('.card-labels');
                    if (emptyLabels != null) {
                        emptyLabels.parentNode.removeChild(emptyLabels);
                    }
                }

                // Image
                if (o.show_image) {
                    var preview = clone.querySelector('.media-preview'),
                        thumb = clone.querySelector('.media-preview img');
                    if (asset.preview.url) {
                        preview.classList.remove('media-preview-{{uuid}}','{{type}}-asset');
                        preview.classList.add(asset.type + '-asset','media-preview-' + asset.uuid);
                        thumb.src = asset.preview.url;
                        thumb.alt = asset.title;
                    } else {
                        // Remove image from template
                        card.classList.remove('has-image');
                        var emptyImage = clone.querySelector('.card-image');
                        emptyImage.parentNode.removeChild(emptyImage);
                    }
                }

                // Social share
                var shareLinks = clone.querySelectorAll('.tnt-share-link');
                for (i = 0; i < shareLinks.length; i++) {
                    shareLinks[i].href = shareLinks[i].href.replace(/%7B%7Burl%7D%7D/g, asset.url);
                }

                // Asset links
                var assetLinks = clone.querySelectorAll('.tnt-asset-link');
                for (i=0; i<assetLinks.length; i++) {
                    assetLinks[i].href = asset.url;
                }

                // Comment links
                if (o.show_comment_count) {
                    var href = clone.querySelector('.cm').getAttribute('href');
                    href = href.replace('{{url}}', asset.url);
                    clone.querySelector('.cm').setAttribute('href', href);
                }

                // Push completed card
                cards.push(document.importNode(clone, true));
            });
        }

        // Get asset summary
        function getSummary(asset) {
            let summary, 
                isPrologue = false;
            if (asset.prologue) {
                summary = asset.prologue;
                isPrologue = true
            } else {
                for (var i = 0; i < asset.content.length; i++) {
                    if (asset.content[i].startsWith('<p>')) {
                        summary = asset.content[i];
                        break;
                    }
                }
            }
            if (summary) {
                // stripping tags
                summary = summary.replace(/(?:<p[^>]*>)|(?:<\/p>)/g,'');
                
                if (o.text_truncate != 0 && (!isPrologue || (isPrologue && o.summary_truncate))) {
                    summary = summary.substr(0, o.text_truncate);
                    summary += '&hellip;';
                }
                if (o.read_more_link) {
                    summary += ' <a href="' + asset.url + '" class="read-more-link">' + o.read_more_text  + '</a>'
                }
                return summary;
            }
        }

        // Get author name 
        function getAuthorName(author) {
            if (author.first_name != null && author.last_name != null) {
                return (author.first_name + ' ' + author.last_name);
            } else {
                return author.screen_name;
            }
        }

        // Gets the current UNIX timestamp without miliseconds
        function getCurrentTime() {
            var time = new Date();
            return Math.floor(time.getTime() / 1000);
        }

        function prettyDate(asset_timestamp, current_timestamp) {
            // define string formats
            var secString = 'sec';
            var minString = 'min';
            var hourString = 'hr';

            // comparison values
            var second = 1;
            var minute = 60 * second;
            var hour = 60 * minute;

            // comparison int
            var delta = current_timestamp - asset_timestamp;

            // return matched value
            if (delta < 0) return 'not yet';
            if (delta < (1 * minute)) return Math.floor(delta) + ' ' + secString + ' ago';
            if (delta < (2 * minute)) return '1 ' + minString + ' ago';
            if (delta < (60 * minute)) return Math.floor(delta / minute) + ' ' + minString + ' ago';
            if (delta < (120 * minute)) return '1 ' + hourString + ' ago';
            if (delta < (24 * hour)) return Math.floor(delta / hour) + ' ' + hourString + ' ago';

            // default return
            return '@' + asset_timestamp;
        }
    });
})(window, document);